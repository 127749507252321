import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap

const Skills = ({ frontendSkills, backendSkills, databaseSkills, devopsSkills }) => {
  return (
    <section className="container pt-5 skills d-flex justify-content-center">
      <div className="row row-cols-1 row-cols-md-2 g-4 align-items-center">
        {/* Frontend Skills */}
        <div className="col">
          <div className="card bg-dark border-0 text-white h-100">
            <div className="card-body d-flex flex-column">
              <h5 className="card-title">Frontend</h5>
              <ul className="list-group list-group-flush">
                {frontendSkills.map((skill, index) => (
                  <li key={index} className="list-group-item">{skill}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Backend Skills */}
        <div className="col">
          <div className="card bg-dark border-0 text-white h-100">
            <div className="card-body d-flex flex-column">
              <h5 className="card-title">Backend</h5>
              <ul className="list-group list-group-flush">
                {backendSkills.map((skill, index) => (
                  <li key={index} className="list-group-item">{skill}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Database Skills */}
        <div className="col">
          <div className="card bg-dark border-0 text-white h-100">
            <div className="card-body d-flex flex-column">
              <h5 className="card-title">Database</h5>
              <ul className="list-group list-group-flush">
                {databaseSkills.map((skill, index) => (
                  <li key={index} className="list-group-item">{skill}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* DevOps Skills */}
        <div className="col">
          <div className="card bg-dark border-0 text-white h-100">
            <div className="card-body d-flex flex-column">
              <h5 className="card-title">DevOps</h5>
              <ul className="list-group list-group-flush">
                {devopsSkills.map((skill, index) => (
                  <li key={index} className="list-group-item">{skill}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;

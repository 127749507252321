import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap
import Projects from './components/Projects';
import Skills from './components/Skills';
import Introduction from './components/Introduction';
import Experience from './components/Experience';

function App() {
  // ... your data definitions remain unchanged ...
  const projects = [
    {
      title: 'Chatting Application',
      description: ['Node', 'Express', 'Redis', 'Socket.io'],
      image: 'assets/chatapp.jpeg',
      githubLink: 'https://github.com/rajsharma2407/Web-Chat'
    },{
      title: 'Ecommerce Website',
      description: ['Node', 'Express', 'Mongo DB'],
      image: 'assets/amazon.webp',
      githubLink: 'https://github.com/rajsharma2407/skipcart'
    },{
      title: 'Hospital Management System',
      description: ['Swing Java', 'MySQL DB'],
      image: 'assets/hpms.jpeg',
      githubLink:'https://github.com/rajsharma2407/Hospital-Management-System'
    }
  ];


  const frontendSkills = [ 'React', 'Angular','TypeScript','HTML', 'CSS', 'JavaScript',];
  const backendSkills = ['Node.js', 'Express.js', 'Spring Boot', 'FastAPI'];
  const databaseSkills = ['MongoDB', 'MySQL'];
  const devopsSkills = ['AWS', 'Git', 'Docker', 'Kubernetes'];

  const experiences = [
    {
      company: 'Consultadd services pvt ltd',
      designation: 'Software Engineer',
      timeline: 'Jan 2023 - Present',
      work: [
        'Created several APIs for a background verification application example including gathering processed data of a single person from different services and send them back to their organization.',
        'Worked on alerts and monitoring by integrating AWS CloudWatch with Edge Delta and Pager Duty by using loggers and matching messages using Regex.'
      ]
    },{
      company: 'Quest Global Technologies',
      designation: 'Full Stack Developer Intern',
      timeline: 'June 2022 - Aug 2022',
      work: [
        'Redesign previous applications by adding saga middleware to act on an action before it reaches the reducer, giving users more control over the impacts.',
        'Developed easy-to-use use application by converting the application into a React App which also increased the performance because of its fast loading time using virtual DOM.'
      ]
    },{
      company: 'Techguy Services',
      designation: 'Full Stack Developer Intern',
      timeline: 'Oct 2021 - Nov 2021',
      work: [
        'Built the admin panel of Techguy services to provide an interface with the help of which admins can manage critical tasks easily.',
        'Implemented various APIs for Techguy Employees of different roles to grant them privileges so they could manage anything as needed.',
        'Used Amazon S3 service to standardize the way of storing and accessing important documents securely.'
      ]
    },
    // Add more experience objects as needed
  ];

  return (
    <div className="container-fluid px-0">
      <Introduction />
      <main className="container py-5">
        <div className="row d-flex flex-column align-items-center">
          <div className="col mb-5">
            <h2 className="mb-3">Skills</h2>
            <Skills
              frontendSkills={frontendSkills}
              backendSkills={backendSkills}
              databaseSkills={databaseSkills}
              devopsSkills={devopsSkills}
            />
          </div>
          <div className="col mb-5">
            <h2 className="mb-3">Projects</h2>
            <Projects projects={projects} />
          </div>
          <div className="col">
            <h2 className="mb-3">My Experience</h2>
            <Experience experiences={experiences} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;

import React from 'react';


const Introduction = () => {
  return (
    // <div className="container introduction mt-5">
    //   <div className="row">
    //     <div className="col-2 image-container">
    //       <img className="img-thumbnail rounded-circle profile-image" src="/assets/profile-pic.webp" alt="Profile" />
    //     </div>
    //     <div className="col-8 text-container mx-auto">
    //       <h1 className="name">Raj Sharma</h1>
    //       <p className="lead description">Hello, I am a software developer.</p>
    //     </div>
    //   </div>
    // </div>
    <header className="bg-dark py-5">
        <div className="container d-flex flex-column align-items-center text-center">
        <div className="row">
           <div className="col-2 image-container">
             <img className="img-thumbnail rounded-circle profile-image" src="/assets/profile-pic.webp" alt="Profile" />
           </div>
           <div className="col-8 text-container">
          <h1 className="text-white mb-0">Raj Sharma</h1>
          <p className="text-white lead">Hello, I am a Software Developer.</p>
           </div>
         </div>
        </div>
      </header>
  );
}

export default Introduction;

import React from 'react';

const Experience = ({ experiences }) => {
  return (
    <section className="container py-5 experience">
      {experiences.map((experience, index) => (
        <div key={index} className="card mb-4">
          <div className="card-body p-4 text-black">
            <div className="d-flex align-items-center pb-3">
              <div className="flex-grow-1">
                <h5 className="card-title">{experience.company}</h5>
                <p className="card-text mb-0">{experience.timeline}</p>
              </div>
            </div>
            <h6 className="card-subtitle mt-3">{experience.designation}</h6>
            <ul className="list-group list-group-flush experience-work-details">
              {experience.work.map((detail, i) => (
                <li key={i} className="list-group-item">{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Experience;
